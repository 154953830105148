::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #666;
}

.markercluster-map {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.grid-stack > .grid-stack-item > {
  background-color: red;
  border-radius: 10px;
}

.maximize-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1300;
  right: 0;
  overflow-y: scroll;
}