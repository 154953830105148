
@-webkit-keyframes leftAnim {
  0% {
    -webkit-transform: translateX(97px);
    -moz-transform: translateX(97px);
    transform: translateX(97px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes leftAnim {
  0% {
    -webkit-transform: translateX(97px);
    -moz-transform: translateX(97px);
    transform: translateX(97px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
  }
}
@-ms-keyframes leftAnim {
  0% {
    -webkit-transform: translateX(97px);
    -moz-transform: translateX(97px);
    transform: translateX(97px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes leftAnim {
  0% {
    -webkit-transform: translateX(97px);
    -moz-transform: translateX(97px);
    transform: translateX(97px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes rightAnim {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-93px);
    -moz-transform: translateX(-93px);
    transform: translateX(-93px);
  }
}
@-moz-keyframes rightAnim {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-93px);
    -moz-transform: translateX(-93px);
    transform: translateX(-93px);
  }
}
@-ms-keyframes rightAnim {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-93px);
    -moz-transform: translateX(-93px);
    transform: translateX(-93px);
  }
}
@keyframes rightAnim {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-93px);
    -moz-transform: translateX(-93px);
    transform: translateX(-93px);
  }
}
.loader {
  width: 300px;
  height: 150px;
  margin: 0 auto;
  position: relative;
}
.loader::after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 50%;
  margin-left: -1px;
  width: 2px;
  z-index: 1;
  height: 150px;
  border-left: 2px dotted #000;
}
.loader__box {
  position: absolute;
  top: 0;
  width: 150px;
  height: 150px;
  overflow: hidden;
  z-index: 2;
}
.loader__left {
  left: 0;
}
.loader__left .loader__circle {
  background-color: #fff;
  right: 10px;
  -webkit-animation: leftAnim 1.3s ease-in-out alternate infinite;
  -moz-animation: leftAnim 1.3s ease-in-out alternate infinite;
  animation: leftAnim 1.3s ease-in-out alternate infinite;
}
.loader__right {
  right: 0;
}
.loader__right .loader__circle {
  left: 10px;
  -webkit-animation: rightAnim 1.3s ease-in-out alternate infinite;
  -moz-animation: rightAnim 1.3s ease-in-out alternate infinite;
  animation: rightAnim 1.3s ease-in-out alternate infinite;
}
.loader__circle {
  width: 75px;
  height: 75px;
  border: solid 2px black;
  display: block;
  background-color: #000;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  margin-top: -37.5px;
}

.v_align {
  position: absolute;
  text-align: center;
  left: 0;
  width: 100%;
  /* max-height: 90vh; */
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

